<template>
  <div>
    <StringboxFarmComparitonNew :stringdata="stringdata" />
  </div>
</template>

<script>
// import StringboxFarmCompariton from '@/views/components/Stringbox/StringboxFarmCompariton.vue';
import StringboxFarmComparitonNew from '@/views/components/Stringbox/StringboxFarmComparitonNew.vue';

export default {
  components: {
    StringboxFarmComparitonNew,
  },
  data() {
    return {

    };
  },
  computed: {
    stringdata() {
      const windowsWidth = document.documentElement.clientWidth;

      const { stringboxMeasureList, santralDataList, santralStringboxData } = this.$store.getters;
      if (
        Object.keys(stringboxMeasureList).length > 0
        && Object.keys(santralDataList).length > 0
        && Object.keys(santralStringboxData).length > 0
      ) {
        return {
          measures: stringboxMeasureList,
          santralList: santralDataList,
          santralStringboxData,
          windowsWidth,
        };
      }
      return null;
    },
  },

  mounted() {

  },
  methods: {},
};
</script>
